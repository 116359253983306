<template>
  <div>
    <header-nav :bg="bg" :text="text" :index="3"/>
    <div class="h1">热门文章</div>
    <div class="article-wrap">
      <a v-for="(item, index) in hotArticle" v-bind:key="index" :href="item.href" target="_blank" >
        <div class="article">
          <span>{{item.title}}</span>
          <span>{{item.date}}</span>
        </div>
      </a>
    </div>
    <div class="h1">近期文章</div>
    <div class="article-wrap">
      <a v-for="(item, index) in lasteArticle" v-bind:key="index" :href="item.href" target="_blank" >
        <div class="article">
          <span>{{item.title}}</span>
          <span>{{item.date}}</span>
        </div>
      </a>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderNav from '../../components/HeaderNav'
import Footer from '../../components/Footer'
import bg from '../../assets/header-bg3.png'

export default {
  components: { HeaderNav, Footer },
  data() {
    return {
      bg,
      text: '资讯中心',
      lasteArticle: [{
        title: '燃！从战士到硕士，“老兵”百日上岸经验！',
        date: '2020.06.30',
        href: 'https://mp.weixin.qq.com/s/zhzYSC7l-Ue-Q6_mgJ3odg'
      }, {
        title: '考研人都是怎么坚持下来的？实用小技巧！',
        date: '2020.06.10',
        href: 'https://mp.weixin.qq.com/s/jdRdhlgnbQfNLkqox1_r5w'
      }, {
        title: '摔下楼梯那天，我悟到了一个考研真相！',
        date: '2020.05.20',
        href: 'https://mp.weixin.qq.com/s/awH_aySXmdmVT7v92TGMfw'
      }, {
        title: '考研不足160天，暑假复习规划来了！！！',
        date: '2020.05.12',
        href: 'https://mp.weixin.qq.com/s/A-wI214yQzcWijwEltJldA'
      }, {
        title: '重磅！教育部最新：497所高校招第二学位！',
        date: '2020.04.15',
        href: 'https://mp.weixin.qq.com/s/ojwPyqaQC-MRS-bcfAeSuA'
      }],
      hotArticle: [{
        title: '研究生9月1日开学！录取通知书陆续发放中……',
        date: '2020.06.28',
        href: 'https://mp.weixin.qq.com/s/FpoQjmdfgFF4gLjGiGjYzw'
      }, {
        title: '新招生简章汇总！专业目录&考试大纲来了！',
        date: '2020.06.06',
        href: 'https://mp.weixin.qq.com/s/Lr1V_3twsnA3r1ExlE7_rw'
      }, {
        title: '目标985，每天高效复习10小时秘笈，收藏！',
        date: '2020.05.25',
        href: 'https://mp.weixin.qq.com/s/qML40hbD2bLV94jIDyx8ZA'
      }, {
        title: '79%考生进度落后，52%学习不足7小时！',
        date: '2020.05.14',
        href: 'https://mp.weixin.qq.com/s/tOoI9n9KBAVrtemCrAYO-A'
      }, {
        title: '初试时间提前？这15个重要时间点，你必须知道！',
        date: '2020.04.26',
        href: 'https://mp.weixin.qq.com/s/aIOYNGVd0FlvgIMX-VFBeg'
      }]
    }
  }
}
</script>

<style lang="less" scoped>
  .h1{
    margin-top: 72px;
    margin-bottom: 34px;
    font-size: 30px;
    color: #000000;
    text-align: center;
  }
  .article-wrap{
    width: 730px;
    margin: 0 auto;
    a{
      text-decoration: none;
      color: #595959;
    }
    .article{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      color: #595959;
      font-size: 14px;
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width:500px){
    .article-wrap{
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
    }
  }
</style>